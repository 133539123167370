import React from "react";

import ProductCard from "../../../components/ProductCards/ProductCards";

import { BorgonaEco } from "../../../data/borgona_eco";

import WineBottle from "../botellas_vino";

const BorgonaEcoView = () => {
  return (
    <WineBottle subtitle="Borgoña Eco">
      <ProductCard data={BorgonaEco} />
    </WineBottle>
  );
};

export default BorgonaEcoView;
